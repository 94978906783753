import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_comment_autocomplete = _resolveComponent("vue-comment-autocomplete")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_vue_comment_autocomplete, {
      source: "/completion/prompt/users",
      placeholder: "Napisz komentarz... (Ctrl+Enter aby wysłać)",
      modelValue: _ctx.microblog.text,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.microblog.text) = $event)),
      "allow-paste": "",
      onPaste: $options.addAsset,
      onSave: $options.saveComment,
      onCancel: _ctx.cancel,
      ref: "commentPrompt"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.saveComment && $options.saveComment(...args))),
          class: "btn btn-sm btn-comment-submit",
          title: "Zapisz (Ctrl+Enter)"
        }, [
          _createVNode(_component_vue_icon, { name: "microblogCommentNewSave" })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onPaste", "onSave", "onCancel"])
  ]))
}