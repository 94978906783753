
import IsImage from "../../libs/assets";
import Textarea from '../../libs/textarea';
import store from "../../store/index";
import VueCommentAutocomplete from '../CommentAutocomplete.vue';
import VueIcon from "../icon";
import {MicroblogFormMixin} from '../mixins/microblog';

export default {
  name: 'microblog-comment-form',
  store,
  components: {
    VueIcon,
    'vue-comment-autocomplete': VueCommentAutocomplete,
  },
  mixins: [MicroblogFormMixin],
  methods: {
    focus() {
      this.$refs.commentPrompt.focus();
    },
    saveComment() {
      this.save('microblogs/saveComment');
    },
    addAsset(asset) {
      this.microblog.assets.push(asset);
      this.insertAssetAtCaret(asset);
    },
    insertAssetAtCaret(asset) {
      this.$refs.commentPrompt.inspect(textarea => {
        new Textarea(textarea).insertAtCaret((IsImage(asset.name) ? '!' : '') + '[' + asset.name + '](' + asset.url + ')', '', '');
        textarea.dispatchEvent(new Event('input', {'bubbles': true}));
      });
    },
  },
};
